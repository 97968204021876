<template>
    <transition name="fade">
        <div class="page">
        <div class="secondary-header" v-if="ready">
            <div @click="() => { this.$router.push({path: '/channels'})}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M15 6L9 12L15 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                Channels
            </div>

            <div class="save" v-ripple @click="save">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="this.saved ? 'saved' : ''">
                    <path d="M5 21V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L13.0815 17.1953C12.4227 16.7717 11.5773 16.7717 10.9185 17.1953L5 21Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>


        <div class="sports-wrapper" v-if="ready">
            <div class="logo" ref="logo">
              <img ref="img" class="channel-logo" :src="channel.asset.img" v-if="channel.asset && channel.asset.img" loading="lazy" />
              <h3 ref="name" v-else> {{channel.name}} </h3>
            </div>

              <div class="stream-wrapper">
                  <h3> Streams </h3>
                  <div class="streams" v-if="ready">
                      <div v-for="(s,i) in links" :key="i" class="stream" v-ripple @click="play(s.link)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                          {{s.name}}
                      </div>
                  </div>
              </div>



              <div class="section ota" v-if="ready && channel.schedule.length > 0">
                  <div class="content">
                      <h3>  On The Air </h3>
                  </div>
              </div>


              <div class="tabs" v-if="this.channel.schedule">
                <div
                    v-for="(date, index) in dates"
                    :key="index"
                    class="tab"
                    :class="{'active': activeDate === date}"
                    @click="changeActiveDate(date)"
                    v-ripple
                >
                    {{ `${date.split('-')[1] } / ${date.split('-')[2] }`}}
                </div>
            </div>

                <div class="schedule" v-if="this.channel.schedule">
                    <div v-for="(e, i) in groupedEvents[activeDate]" :key="i" :style="getHeight(e)" class="item">
                            <div class="details">
                                <p> {{ e.name }} </p>
                                <small>
                                </small>
                                <span> {{getTime(e.start)}} - {{getTime(e.end)}} </span>
                            </div>
                      </div>
              </div>


              <div class="section">
                  <div class="content">
                      <h3> More like {{ channel.name }} </h3>
                    </div>
                </div>
             </div>

            <div class="content-row related">
                <div v-for="(c,i) in channels.related" :key="i + c.id">
                    <Channel :channel="c" v-ripple/>
                </div>
            </div>

      </div>
    </transition>
  </template>

  <script>
  const Channel  = () => import('@/components/Channel')


  export default {
    name: 'ChannelView',

    components: {
      Channel,
    },

    watch: {
        $route() {
          this.load()
          this.$nextTick(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          })
        }
    },

    data: function(){
      return {
        saved: false,
        activeDate: '',
          channel: {
            schedule: []
          },
          channels: [],
          links: [],
          ready: false,
      }
    },

    methods: {
    async save(){
        let data = {
             type: 'live',
              id: this.channels.channels[0].id,
              ref: window.location.path,
              name: this.channel.name,
              imgs: [this.channel.asset.img],
              color: window.getAssetColor(this.channel.asset),
        }

        const req = await fetch(process.env.VUE_APP_SERVER + `/save`, {method: 'POST', body: JSON.stringify(data), headers: { "Content-Type": "application/json" } })
        const res = await req.json()
        this.saved = res.saved
    },


      play(url){
          if(!window.did){
              this.$root.$emit('collectDid', {cb: this.play.bind(this), params: url})
              return
          }
          const logo = []
          if(this.channel.asset){ logo.push(this.channel.asset.img) }

          const data = {
              type: 'live',
              id: this.channels.channels[0].id,
              did: window.did,
              url: url,
              ref: window.location.href,
              name: this.channel.name,
              logos: logo,
              color: window.getAssetColor(this.channel.asset),
              details: {}
          }

          fetch(process.env.VUE_APP_SERVER + `/start`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: { "Content-Type": "application/json" }
          })
      },

      getHeight(e){
          if(e){
              const pixelsPerHour = 100;

              const start = new Date(e.start);
              const end = new Date(e.end);

              const currentTime = new Date();

              if (end <= currentTime) { return {display: 'none'}; }

              const duration = end.getTime() - start.getTime();
              const hours = duration / (1000 * 60 * 60);
              const height = hours * pixelsPerHour;
              return {height: height + 'px'};

          }
      },

      getTime(time){ return window.getTime(time) },

      async getChannelDetails(){
          const req = await fetch(`${process.env.VUE_APP_SERVER}/epgChannel?id=${this.$route.params.id}`)
          const data = await req.json()
          this.channels = data
          this.saved = data.saved
          this.links = data.channels.reduce((acc, curr) => {
            return acc.concat(curr.links);
        }, [])
      },

      changeActiveDate(date) {
      // Update the active date
      this.activeDate = date;
    },
    formatDate(date) {
      // Format date for display
      const options = { year: 'numeric', month: 'long', day: 'numeric',timeZone: "America/Denver" };
      return new Date(date).toLocaleDateString("en-US", options).split(',')[0];
    },

      async load(){
          this.ready = false;
          const req = await fetch(`${process.env.VUE_APP_SERVER}/guide?id=${this.$route.params.id}`)
          const data = await req.json()
          this.channel = data.channels[0];
          await this.getChannelDetails()
          this.$nextTick(() => {
            this.ready = true
            this.$nextTick(() => {
                this.$refs.logo.style.background = window.getAssetColor(this.channel.asset)
            })
          })

      }
    },

    computed: {
    groupedEvents() {
      const grouped = {};
      for (const event of this.channel.schedule) {
        const endDate = new Date(event.end);
        const currentDate = new Date(event.start);
        if(endDate >= new Date() ){
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const date = `${year}-${month}-${day}`
            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(event);
        }
        }
      return grouped;
    },
    dates() {
      // Get unique dates from events
      return Object.keys(this.groupedEvents);
    }

    },

    mounted: function(){
      this.load()
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        this.$nextTick(() => {

        this.changeActiveDate(`${year}-${month}-${day}`)
        })
    }
  }

  </script>

  <style scoped lang="scss">
  .content-row {
      padding-left: 0;
  }
  .sports-wrapper{
      > div:not(.stream-wrapper){
          padding-left: 20px;
          padding-right: 20px;
      }
      .stream-wrapper h3{
          padding-left: 20px;
      }
      .details{ }
      .logo{
          box-sizing: content-box;
          width: 100%;
          overflow: hidden;
          background-color: rgb(81, 81, 81);
          height: 225px;
          z-index: 0;
          padding: 10px 0;
          padding-top: 25px;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          .channel-logo{
              padding: 15px;
              max-height: 50%;
              max-width: 60vw;
              border-radius: 5px;
              border-bottom-right-radius: 0px;
              object-fit: contain;
          }
      }
  }

  .content-row{
     width: 100%;
     margin: 20px 0;
     padding-left: 20px;
  }

  .streams{
      display: flex;
      flex-direction: row;
      margin:20px 0;
      margin-bottom: 30px;
      padding-left: 20px;
      overflow-x: scroll;
      align-items: center;
      height: 75px;

      svg{
          height: 15px;
          margin-bottom: 10px;
          margin-top: 5px;
      }

      .stream{
          font-size: 9px;
          border: 1px solid white;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #111;
          margin-right: 10px;
          padding: 5px 10px;
          padding-bottom: 10px;
          box-sizing: border-box;
          border-radius: 5px;
          min-width: 100px;
          overflow: hidden;
          text-align: center;
      }
  }


  .ota{
      margin-top: 40px
  }

  .schedule{
    padding: 10px;
    padding-bottom: 20px;
   .item{
      border-left: 1px solid white;
      padding-left: 10px;
      margin-left: 10px;
      min-height: 55px;
      max-height: 300px;

      p{
          margin-bottom: 0;
      }
      span{
          font-size: 10px
      }
   }
  }

  .content-row.related{
      > div:last-of-type{
          padding-right: 40px;
      }
  }


.tabs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin-bottom: 20px;
    .tab{
        margin-right: 5px;
        border: 1px solid white;
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 10px;
        font-size: 9px;
        &.active{
            color: black;
            background-color: white;
        }
    }
}
  </style>

